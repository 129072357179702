import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR, VUE_APP_AMAZONIA_DIRECTION } = process.env;

export default class ShieldService {

  static async create(arrayShoppingCart, servicesData, planData, token) {
    try {
      const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/cartSoap/create`, {
        arrayShoppingCart: arrayShoppingCart,
        servicesData: servicesData,
        planData: planData,
        sponsor: VUE_APP_SPONSOR,
      },
        {
          headers: {
            // Se agrega el Header de authorización
            "x-captcha-authorization": token,
          },
        }
      );

      return shieldRes.data;

    } catch (error) {
      return 'failed'
    }
  }

  static async getPlans(sponsor) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/car?sponsor=${sponsor}`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getCars(keygen) {
    const vaultRes = await axios.post(`${VUE_APP_SHIELD_URL}/cartSoap/getCars`,
      {
        keygen: keygen,
      }
    );

    return vaultRes.data;
  }

  static async getToken(token) {
    const CarsTokenRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/cartSoap/get/${token}`
    );

    return CarsTokenRes.data.data;
  }

  static async getKeygen(keygen) {
    const CarsTokenRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/cartSoap/getKeygen/${keygen}`
    );

    return CarsTokenRes.data.data;
  }

  static async deleteCar(id, token) {
    const shieldRes = await axios.delete(`${VUE_APP_SHIELD_URL}/cartSoap/deleteCar/${id}`, {
      headers: {
        // Se agrega el Header de autorización
        "x-captcha-authorization": token,
      },
    });
    return shieldRes.data;
  }

  static async consultaCar(patente, rut) {
    try {

      const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/cartSoap/getPatente/${patente}/${rut}`
      );
      return shieldRes.data;

    } catch (error) {
      return (error.response.status)
    }
  }

  static async consultasoapAprobado(patente) {
    const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/cartSoap/consultasoapAprobado/${patente}`
    );
    return shieldRes.data;
  }

  static async createSoapConsulta(propietarioData, carData, token) {

    try {

      const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/cartSoap/createSoapConsulta`,
        {
          propietarioData: propietarioData,
          carData: carData,
          sponsor: VUE_APP_SPONSOR,
        },
        {
          headers: {
            // Se agrega el Header de authorización
            "x-captcha-authorization": token,
          },
        }
      );
      return shieldRes.data;

    } catch (error) {
      console.log(error)
      return 'failed'
    }

  }

  static async getAmazonia(polizaNumber, token) {
    try {

      var frontendName = VUE_APP_SPONSOR;
      var redirect = VUE_APP_AMAZONIA_DIRECTION;

      const vaultRes = await axios.get(`${VUE_APP_SHIELD_URL}/cartSoap/getAmazonia/${polizaNumber}/${frontendName}/${redirect}`,
        {
          headers: {
            // Se agrega el Header de authorización
            "x-captcha-authorization": token,
          },
        }
      );
      return vaultRes.data;

    } catch (error) {
      console.log(error)
      return 'failed'
    }
  }

}
